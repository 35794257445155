import { setCMSBarWidth } from './cms-bar-width'


import './select_checkbox'
import './required_checkbox'
import { cooperationTable } from './cooperation_price_list'
import { initMenuControl } from './menu_control'

document.addEventListener('DOMContentLoaded', () => {
    for (const item of document.querySelectorAll('.page-navigation li a')) {
        const itemHref = item.getAttribute('href')
        if (typeof itemHref === 'string' && itemHref.includes('#link-hide')) {
            item.removeAttribute('href')
        }
    }

    // Because message 'Enter a valid email address.' is hidden.
    for (const email of document.querySelectorAll('.base-form input[type=email].has-error')) {
        if (email.value) {
            email.classList.add('email-error')
        }

        email.addEventListener('input', () => {
            email.classList.remove('email-error')
        })
    }

    for (const svg of document.querySelectorAll('.cooperation aside img')) {
        svg.insertAdjacentHTML('afterend', `<div class="info-tooltip" >${svg.alt}</div>`)
    }

    // Open according collapse
    for (const collapse of document.querySelectorAll('.collapse-box')) {
        if (collapse.querySelector('*[id*="open"')) {
            collapse.querySelector('header[id^=trigger-open]').classList.remove('collapsed')
            collapse.querySelector('header[id^=trigger-open]').setAttribute('aria-expanded', true)
            collapse.querySelector('aside[id^=open]').classList.add('collapse', 'show')
        }
    }

    for (const item of document.querySelectorAll('.prevent-default')) {
        item.href = ''
    }

    // Add subject for link with mailto
    for (const btn of document.querySelectorAll('.btn[href^="mailto"]')) {
        if (btn.dataset.subject) {
            btn.href = `${btn.href}?subject=${btn.dataset.subject}`
        }
    }
    cooperationTable()
    initMenuControl()

    const structure = document.querySelector("a[href=?structure]")
    if (structure) {
        structure.addEventListener('click', () => {setTimeout(setCMSBarWidth, 1000)}, true)
    }
})

addEventListener("resize", cooperationTable)

import './cms_toolbar'
